import featured1 from '../assets/images/crypto-screener-cover-image.jpg';
import featured2 from '../assets/images/agency-website-cover-image.jpg';
import project1 from '../assets/images/fashion-studio-website.jpg';
import project2 from '../assets/images/nft-collection-website-cover-image.jpg';

import projectBreakingBad from '../assets/images/react-projects/breaking-bad-cast.PNG';
import projectCryptoDashboard from '../assets/images/react-projects/cryptocurrency-dashboard.PNG';
import projectCocktailRecipeApp from '../assets/images/react-projects/cocktail-recipe-app.PNG';
import projectWordleApp from '../assets/images/react-projects/wordle-clone.PNG';
import projectYoutubeReplica from '../assets/images/react-projects/modern-youtube-app.PNG';
import projectSpotifyClone from '../assets/images/react-projects/spotify-clone.PNG';
import projectAdminDashboard from '../assets/images/react-projects/admin-dashboard.PNG';

import websiteVideoPlatform from '../assets/images/websites-projects/video-platform-website.PNG';
import websiteRestaurantLanding from '../assets/images/websites-projects/restaurant-landing-page.PNG';
import websitePhotographyPortfolio from '../assets/images/websites-projects/photography-website.PNG';
import websiteNatureTours from '../assets/images/websites-projects/nature-tours.PNG';
import websiteMetaverse from '../assets/images/websites-projects/metaverse-lanidng-page.png';

export const CONSTANTS = {
  navbar: {
    iconTitle: 'KK',
  },
  homepage: {
    title: 'Turning Vision Into Reality With Code And Design.',
    subtitle:
      'As a skilled full-stack developer, I am dedicated to turning ideas into innovative web applications. Explore my latest projects and articles, showcasing my expertise in React.js and web development.',
    resumeButton: 'CV',
    contactButton: 'Contact',
    hireMeButton: 'Hire Me',
  },
  about: {
    title: 'Passion Fuels Purpose! ',
    aboutMeTitle: 'About Me',
    aboutMeText1:
      "Hi, I'm Kyle, a web developer and UI/UX designer with a passion for creating beautiful, functional, and user-centered digital experiences. With 2 years of experience in the field. I am always looking for new and innovative ways to bring my clients' visions to life.",
    aboutMeText2:
      "I believe that design is about more than just making things look pretty – it's about solving problems and creating intuitive, enjoyable experiences for users. ",
    aboutMeText3:
      "Whether I'm working on a website, mobile app, or other digital product, I bring my commitment to design excellence and user-centered thinking to 		every project I work on. I look forward to the opportunity to bring my skills and passion to your next project.",
    aboutStat1: {
      number: 4,
      description: 'Satisfied clients',
    },
    aboutStat2: {
      number: 30,
      description: 'Projects completed',
    },
    aboutStat3: {
      number: 2,
      description: 'Years of work experience',
    },
    experienceData: [
      {
        position: 'Consultant',
        company: 'NetCompany',
        companyLink: 'https://www.netcompany.com/en-GB',
        time: 'October 2022 - August 2023',
        address: 'Copenhagen, Denmark',
        work: '•Working as a consultant, working with the Frontend development team, specifically in React Native, React JS and Vue JS to build a customer focused solution<br/>•Had more senior experience working as a team lead, leading client meetings and project management skills managing a frontend team myself<br/>•Worked on “mit.dk”, a Danish digital post platform/service<br/>•Also had experience working with documentation, management of tasks, collaborating with UX/UI team and pitching new ideas to product owners<br/>•Had the opportunity to live in Denmark for 1 year, where I had further opportunities to travel to Poland, Norway and Aalborg',
      },
    ],
    educationData: [
      {
        type: '1st Class with Honors in BsC Computer Science',
        time: '2019-2022',
        place: 'University of Winchester',
        info: "<b>Modules undertaken:</b> intellectual properties and information rights, cloud computing, risk management and cyber security, distributed systems, penetration testing, information management and data analytics, web application development and software development.<br/>Completed my dissertation on 'Standardisation of Electronic Patient Medical Record'",
      },
      {
        type: 'A Levels: Computer Science, Maths, Physics and Sociology',
        time: '2017-2019',
        place: 'Surrey',
        info: '',
      },
    ],
  },
  projects: {
    title: 'View the projects I have worked on!',
    projectData: [
      {
        id: 2,
        type: 'Featured Project',
        title: 'Cryptocurrency Dashboard',
        summary:
          'Cryptocurrency dashboard where you can search cryptos, view details and also view the news. Data is fetched from APIs hosted on RapidApi',
        category: 'React JS',
        demoLink: 'https://kylekumar28.github.io/react/crypto',
        githubLink:
          'https://github.com/kylekumar28/kylekumar28.github.io/tree/master/react/crypto',
        img: projectCryptoDashboard,
      },
      {
        id: 2,
        title: 'Metaversus Landing Page',
        summary:
          'Attempted to create a landing page for a fictional MetaVerse related company.',
        demoLink:
          'https://kylekumar28.github.io/react/metaversus-landing-page/',
        githubLink:
          'https://github.com/kylekumar28/kylekumar28.github.io/tree/master/react/metaversus-landing-page',
        img: websiteMetaverse,
      },
      {
        id: 2,
        title: 'Modern Youtube Replica App',
        summary:
          'Using the Youtube API, I have created a modern version of youtube, with search functionality, channel functionality, and including an embeded youtube player',
        demoLink: 'https://kylekumar28.github.io/react/modern-youtube-app/',
        githubLink:
          'https://github.com/kylekumar28/kylekumar28.github.io/tree/master/react/modern-youtube-app',
        img: projectYoutubeReplica,
      },
      {
        id: 2,
        type: 'Featured Project',
        title: 'Restaurant Landing Page',
        summary:
          'Full restaurant landing page with animations and important sections such as; "our story", "menu" and "contact us"',
        category: 'Functional Websites',
        demoLink:
          'https://kylekumar28.github.io/websites/restaurant-landing-page/',
        githubLink:
          'https://github.com/kylekumar28/kylekumar28.github.io/tree/master/websites/restaurant-landing-page',
        img: websiteRestaurantLanding,
      },
      {
        id: 1,
        title: 'Spotify Clone',
        summary:
          "Using the 'shazam api' I created a spotify clone with working music player and functionality that would appear normally in spotify.",
        demoLink: 'https://kylekumar28.github.io/react/spotify-clone',
        githubLink:
          'https://github.com/kylekumar28/kylekumar28.github.io/tree/master/react/spotify-clone',
        img: projectSpotifyClone,
      },
      {
        id: 3,
        title: 'Admin Dashboard',
        summary:
          'A mock design of an admin dashboard using "@mui", "fullcalendar" and "@nivo"',
        category: 'React JS',
        demoLink: 'https://kylekumar28.github.io/react/admin-dashboard/',
        githubLink:
          'https://github.com/kylekumar28/kylekumar28.github.io/tree/master/react/admin-dashboard',
        img: projectAdminDashboard,
      },
      {
        id: 1,
        title: 'Breaking Bad Cast',
        summary:
          "Using 'breakingbadapi' to generate cards for each cast, along with extra details",
        category: 'React JS',
        demoLink:
          'https://kylekumar28.github.io/react/breaking-bad-cast/index.html',
        githubLink:
          'https://github.com/kylekumar28/kylekumar28.github.io/tree/master/react/breaking-bad-cast',
        img: projectBreakingBad,
      },
      {
        id: 3,
        title: 'Cocktail Recipe App',
        summary:
          'Using the "cocktaildb" api to allow users to search for different cocktails and view instructions and ingredients',
        category: 'React JS',
        demoLink: 'https://kylekumar28.github.io/react/cocktail-recipe-app/#/',
        githubLink:
          'https://github.com/kylekumar28/kylekumar28.github.io/tree/master/react/cocktail-recipe-app',
        img: projectCocktailRecipeApp,
      },
      {
        id: 4,
        title: 'Wordle Clone',
        summary: 'Personal clone of the popular game, Worlde',
        category: 'React JS',
        demoLink: 'https://kylekumar28.github.io/react/wordle/',
        githubLink:
          'https://github.com/kylekumar28/kylekumar28.github.io/tree/master/react/wordle',
        img: projectWordleApp,
      },
      {
        id: 1,
        title: 'Video Platform Website',
        summary:
          'New streaming platform landing page with subtle animations and a more modern youthful design',
        category: 'Functional Websites',
        demoLink:
          'https://kylekumar28.github.io/websites/video-platform-website/',
        githubLink:
          'https://github.com/kylekumar28/kylekumar28.github.io/tree/master/websites/video-platform-website',
        img: websiteVideoPlatform,
      },
      {
        id: 3,
        title: 'Photograpy Portfolio Website',
        summary:
          'Photography portfolio website with smooth scrolling and simple, yet effective layout',
        category: 'Functional Websites',
        demoLink: 'https://kylekumar28.github.io/websites/photography-website/',
        githubLink:
          'https://github.com/kylekumar28/kylekumar28.github.io/tree/master/websites/photography-website',
        img: websitePhotographyPortfolio,
      },
      {
        id: 4,
        title: 'Nature Tours Website',
        summary:
          'Full website for a nature tour company including testimonial section and a parallax effect for the background',
        category: 'Functional Websites',
        demoLink: 'https://kylekumar28.github.io/websites/nature-tours/',
        githubLink:
          'https://github.com/kylekumar28/kylekumar28.github.io/tree/master/websites/nature-tours',
        img: websiteNatureTours,
      },
    ],
  },
  footer: {
    copyright: 'All rights reserved.',
    mainText1: 'Built with',
    mainText2: 'by',
    mainText3: 'KyleKumar',
    emailText: 'Say hello',
  },
};
