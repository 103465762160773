import { Layout, AnimatedText } from '../../components';
import AboutStats from './AboutStats';
import AboutSkills from './AboutSkills';

import { CONSTANTS } from '../../data/constants';
import profilePic from '../../assets/images/profile-pic.jpg';
import Timeline from './Timeline';
import TransitionEffect from '../../components/TransitionEffect';

const About = () => {
  const { about } = CONSTANTS;

  return (
    <>
      <TransitionEffect />
      <main className='flex w-full flex-col items-center justify-center dark:text-light'>
        <Layout className='pt-16'>
          <AnimatedText
            text={about.title}
            className='mb-16 lg:!text-7xl sm:!text-6xl xs:!text-4xl sm:mb-8'
          />

          <div className='grid w-full grid-cols-8 gap-16 sm:gap-8'>
            {/* Details */}
            <div className='col-span-3 xl:col-span-4 flex flex-col items-start justify-start md:order-2 md:col-span-8'>
              <h2 className='mb-4 text-lg font-bold uppercase text-dark/75 dark:text-light/75'>
                {about.aboutMeTitle}
              </h2>
              <p className='font-medium'>{about.aboutMeText1}</p>
              <p className='my-4 font-medium'>{about.aboutMeText2}</p>
              <p className='font-medium'>{about.aboutMeText3}</p>
            </div>

            {/* Profile Picture */}
            <div className='col-span-3 xl:col-span-4 relative h-max rounded-2xl border-2 border-solid border-dark bg-light p-8 dark:bg-dark dark:border-light md:order-1 md:col-span-8'>
              <div className='absolute top-0 -right-3 -z-10 w-[102%] h-[103%] rounded-[2rem] bg-dark dark:bg-light' />
              <img
                src={profilePic}
                alt='KyleKumar'
                className='w-full h-auto rounded-2xl'
              />
            </div>

            {/* Numbers */}
            <div className='col-span-2 xl:col-span-8 xl:flex-row flex flex-col items-end justify-between xl:items-center md:order-3'>
              <AboutStats
                description={about.aboutStat1.description}
                number={about.aboutStat1.number}
              />

              <AboutStats
                description={about.aboutStat2.description}
                number={about.aboutStat2.number}
              />

              <AboutStats
                description={about.aboutStat3.description}
                number={about.aboutStat3.number}
              />
            </div>
          </div>

          <AboutSkills />

          <Timeline timelineType='Experience' data={about.experienceData} />
          <Timeline timelineType='Education' data={about.educationData} />
        </Layout>
      </main>
    </>
  );
};

export default About;
