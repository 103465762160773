import { AnimatedNumber } from '../../components';

const AboutStats = ({ description, number }) => (
  <div className='flex flex-col items-end justify-center xl:items-center'>
    <span className='inline-block text-7xl font-bold md:text-6xl sm:text-lg xs:text-md'>
      <AnimatedNumber value={number} />+
    </span>
    <h2 className='text-xl font-medium capitalize text-dark/75 dark:text-light/75 xl:text-center md:text-lg sm:text-base xs:text-sm'>
      {description}
    </h2>
  </div>
);

export default AboutStats;
