import { Link, useLocation, useNavigate } from 'react-router-dom';

const NavbarMobileLink = ({ to, title, className = '', toggle }) => {
  const router = useLocation();
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    toggle(true);
    navigate(to);
  };

  return (
    <button
      to={to}
      className={`${className} relative group text-light dark:text-dark my-2`}
      onClick={handleClick}
    >
      {title}
      <span
        className={`h-[1px] inline-block bg-light dark:bg-dark absolute left-0 -bottom-0.5 group-hover:w-full transition-[width] ease duration-300 ${
          router.pathname === to ? 'w-full' : 'w-0'
        }`}
      >
        &nbsp;
      </span>
    </button>
  );
};

export default NavbarMobileLink;
