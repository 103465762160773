import { Route, Routes, useLocation } from 'react-router-dom';

import { About, Home, Projects } from './pages';
import { Navbar, Footer } from './components';
import './assets/styles/App.css';
import { AnimatePresence } from 'framer-motion';

const App = () => {
  const router = useLocation();

  return (
    <div className='font-mont bg-light dark:bg-dark w-full min-h-screen'>
      <Navbar />
      <AnimatePresence mode='wait'>
        <Routes>
          <Route key={'/'} path='/' element={<Home />} />
          <Route key={'/about'} path='/about' element={<About />} />
          <Route key={'/projects'} path='/projects' element={<Projects />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </div>
  );
};

export default App;
