import { Link } from 'react-router-dom';

import { GithubIcon } from '../../data/icons';

const Project = ({ title, summary, img, demoLink, githubLink }) => (
  <article className='relative w-full flex items-center justify-center flex-col rounded-2xl border border-solid border-dark bg-light p-6 dark:bg-dark dark:border-light xs:p-4'>
    <div className='absolute top-0 -right-3 -z-10 w-[101%] h-[102%] rounded-[2rem] bg-dark rounded-br-3xl dark:bg-light md:-right-2 md:w-[101%] xs:h-[102%] xs:rounded-[1.5rem]' />
    <Link
      to={demoLink}
      target='_blank'
      className='w-full cursor-pointer overflow-hidden rounded-lg'
    >
      <img src={img} alt={title} className='w-full h-auto' />
    </Link>

    <div className='w-full flex flex-col items-start justify-between mt-4'>
      {/* <span className='text-primary font-medium text-2xl dark:text-primaryDark lg:text-lg  md:text-base'>
        Webpage
      </span> */}
      <Link
        to={demoLink}
        target='_blank'
        className='hover:underline underline-offset-2'
      >
        <h2 className='my-2 w-full text-left text-3xl font-bold lg:text-2xl'>
          {title}
        </h2>
      </Link>
      <p className='my-2 font-medium text-dark dark:text-light sm:text-sm'>
        {summary}
      </p>

      <div className='w-full mt-2 flex items-center justify-between'>
        <Link
          to={demoLink}
          target='_blank'
          className='underline underline-offset-2 text-lg font-semibold md:text-base'
        >
          Live Demo
        </Link>
        <Link to={githubLink} target='_blank' className='w-8 md:w-6'>
          <GithubIcon />
        </Link>
      </div>
    </div>
  </article>
);

export default Project;
