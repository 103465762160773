import { Link, useLocation, useNavigate } from 'react-router-dom';

const NavbarLink = ({ to, title, className = '' }) => {
  const router = useLocation();
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // toggle(true);
    navigate(to);
  };

  return (
    // <Link to={to} className={`${className} relative group`}>
    <button onClick={handleClick} className={`${className} relative group`}>
      {title}
      <span
        className={`h-[1px] inline-block bg-dark absolute left-0 -bottom-0.5 group-hover:w-full transition-[width] ease duration-300 dark:bg-light ${
          router.pathname === to ? 'w-full' : 'w-0'
        }`}
      >
        &nbsp;
      </span>
      {/* </Link> */}
    </button>
  );
};

export default NavbarLink;
