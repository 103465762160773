import NavbarLink from './NavbarLink';
import NavbarMobileLink from './NavbarMobileLink';
import NavbarIcon from './NavbarIcon';
import Logo from './Logo';

import {
  DribbbleIcon,
  GithubIcon,
  LinkedInIcon,
  MoonIcon,
  PinterestIcon,
  SunIcon,
  TwitterIcon,
} from '../../data/icons';
import useTheme from '../../hooks/useTheme';
import { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const Navbar = () => {
  const [colourMode, setColourMode] = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const hamburgerMenuRef = useRef(null);

  const handleHamburgerClick = (fromLink) => {
    setIsOpen((prev) => !prev);
  };

  return (
    <header className='w-full px-32 py-8 font-medium flex items-center justify-between dark:text-light relative z-10 lg:px-16 md:px-12 sm:px-8'>
      <button
        onClick={handleHamburgerClick}
        className='flex-col justify-center items-center hidden lg:flex'
      >
        <span
          className={`bg-dark dark:bg-light block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm  ${
            isOpen ? 'rotate-45 translate-y-1' : '-translate-y-0.5'
          }`}
        ></span>
        <span
          className={`bg-dark dark:bg-light block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm my-0.5 ${
            isOpen ? 'opacity-0' : 'opacity-100'
          }`}
        ></span>
        <span
          className={`bg-dark dark:bg-light block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm  ${
            isOpen ? '-rotate-45 -translate-y-1' : 'translate-y-0.5'
          }`}
        ></span>
      </button>

      {/* Desktop menu */}
      <div className='w-full flex justify-between items-center lg:hidden'>
        <nav>
          <NavbarLink to='/' title='Home' className='mr-4' />
          <NavbarLink to='/about' title='About' className='mx-4' />
          <NavbarLink to='/projects' title='Projects' className='mx-4' />
          {/* <NavbarLink to='/articles' title='Articles' className='ml-4' /> */}
        </nav>

        <nav className='flex items-center justify-center flex-wrap'>
          <NavbarIcon href='/' className='mr-3' icon={<TwitterIcon />} />
          <NavbarIcon href='/' className='mx-3' icon={<GithubIcon />} />
          <NavbarIcon href='/' className='mx-3' icon={<LinkedInIcon />} />
          <NavbarIcon href='/' className='mx-3' icon={<PinterestIcon />} />
          <NavbarIcon href='/' className='ml-3' icon={<DribbbleIcon />} />

          <button
            onClick={() =>
              setColourMode(colourMode === 'light' ? 'dark' : 'light')
            }
            className={`ml-3 flex items-center justify-center rounded-full p-1 ${
              colourMode === 'light'
                ? 'bg-dark text-light'
                : 'bg-light text-dark'
            }`}
          >
            {colourMode === 'dark' ? (
              <MoonIcon className='fill-dark' />
            ) : (
              <SunIcon className='fill-dark' />
            )}
          </button>
        </nav>
      </div>

      {/* Mobile menu */}
      {isOpen ? (
        <motion.div
          initial={{ scale: 0, opacity: 0, x: '-50%', y: '-50%' }}
          animate={{ scale: 1, opacity: 1 }}
          ref={hamburgerMenuRef}
          className='min-w-[70vw] flex flex-col justify-between z-30 items-center fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-dark/90 dark:bg-light/75 rounded-lg backdrop-blur-md py-32'
        >
          <nav className='flex items-center flex-col justify-center'>
            <NavbarMobileLink
              to='/'
              title='Home'
              className=''
              toggle={handleHamburgerClick}
            />
            <NavbarMobileLink
              to='/about'
              title='About'
              className=''
              toggle={handleHamburgerClick}
            />
            <NavbarMobileLink
              to='/projects'
              title='Projects'
              className=''
              toggle={handleHamburgerClick}
            />
            {/* <NavbarMobileLink
              to='/articles'
              title='Articles'
              className=''
              toggle={handleHamburgerClick}
            /> */}
          </nav>

          <nav className='flex items-center justify-center flex-wrap mt-4'>
            <NavbarIcon
              href='/'
              className='mr-3 sm:mx-1'
              icon={<TwitterIcon />}
            />
            <NavbarIcon
              href='/'
              className='mx-3  sm:mx-1 bg-light rounded-full dark:bg-dark '
              icon={<GithubIcon />}
            />
            <NavbarIcon
              href='/'
              className='mx-3  sm:mx-1'
              icon={<LinkedInIcon />}
            />
            <NavbarIcon
              href='/'
              className='mx-3  sm:mx-1'
              icon={<PinterestIcon />}
            />
            <NavbarIcon
              href='/'
              className='ml-3  sm:mx-1'
              icon={<DribbbleIcon />}
            />

            <button
              onClick={() =>
                setColourMode(colourMode === 'light' ? 'dark' : 'light')
              }
              className={`ml-3  sm:mx-1 flex items-center justify-center rounded-full p-1 ${
                colourMode === 'light'
                  ? 'bg-dark text-light'
                  : 'bg-light text-dark'
              }`}
            >
              {colourMode === 'dark' ? (
                <MoonIcon className='fill-dark' />
              ) : (
                <SunIcon className='fill-dark' />
              )}
            </button>
          </nav>
        </motion.div>
      ) : null}

      <div className='absolute left-[50%] top-2 translate-x-[-50%]'>
        <Logo />
      </div>
    </header>
  );
};

export default Navbar;
