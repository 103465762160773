import React from 'react';
import Layout from '../../components/Layout';
import AnimatedText from '../../components/AnimatedText';
import FeaturedProject from './FeaturedProject';
import Project from './Project';
import { Link } from 'react-router-dom';
import featured1 from '../../assets/images/crypto-screener-cover-image.jpg';
import { GithubIcon } from '../../data/icons';
import { CONSTANTS } from '../../data/constants';
import TransitionEffect from '../../components/TransitionEffect';

const Projects = () => {
  const { projects } = CONSTANTS;
  const projectData = projects.projectData;

  return (
    <>
      <TransitionEffect />
      <main className='w-full mb-16 flex flex-col items-center justify-center dark:text-light'>
        <Layout className='pt-16'>
          <AnimatedText
            text={projects.title}
            className='mb-16 lg:!text-7xl sm:!mb-8 sm:!text-6xl xs:!text-4xl'
          />

          <div className='grid grid-cols-12 gap-24  xl:gap-x-16 lg:gap-x-8 md:gap-y-24 sm:gap-x-0'>
            {projectData.map((project) => (
              <div
                className={
                  project.type === 'Featured Project'
                    ? 'col-span-12'
                    : 'col-span-6 sm:col-span-12'
                }
              >
                {project.type === 'Featured Project' ? (
                  <FeaturedProject
                    type={project.type}
                    title={project.title}
                    summary={project.summary}
                    img={project.img}
                    demoLink={project.demoLink}
                    githubLink={project.githubLink}
                  />
                ) : (
                  <Project
                    title={project.title}
                    summary={project.summary}
                    img={project.img}
                    demoLink={project.demoLink}
                    githubLink={project.githubLink}
                  />
                )}
              </div>
            ))}
          </div>
        </Layout>
      </main>
    </>
  );
};

export default Projects;
