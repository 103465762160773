import { motion } from 'framer-motion';
import Skill from './Skill';

const AboutSkills = () => {
  return (
    <>
      <h2 className='font-bold text-8xl mt-64 w-full text-center md:text-6xl md:mt-32 xs:text-4xl md:mb-16'>
        Skills
      </h2>

      <div
        className='w-full h-screen relative flex items-center justify-center rounded-full bg-circularLight dark:bg-circularDark lg:h-[80vh] sm:h-[60vh] xs:h-[50vh]lg:bg-circularLightLg dark:lg:bg-circularDarkLg
			md:bg-circularLightMd dark:md:bg-circularDarkMd
			sm:bg-circularLightSm dark:sm:bg-circularDarkSm'
      >
        <motion.div
          className='flex items-center justify-center rounded-full font-semibold bg-dark text-light p-8 shadow-dark cursor-pointer dark:text-dark dark:bg-light lg:p-6 md:p-4 xs:text-xs xs:p-2'
          whileHover={{ scale: 1.05 }}
        >
          Web
        </motion.div>

        <Skill name='HTML' x='-25vw' y='2vww' />
        <Skill name='CSS' x='-5vw' y='-10vw' />
        <Skill name='Javascript' x='20vw' y='6vw' />
        <Skill name='ReactJS' x='0vw' y='12vw' />
        <Skill name='NextJS' x='-20vw' y='-15vw' />
        <Skill name='GatsbyJS' x='15vw' y='-12vw' />
        <Skill name='Web Design' x='32vw' y='-5vw' />
        <Skill name='Figma' x='0' y='-20vw' />
        <Skill name='Firebase' x='-25vw' y='18vw' />
        <Skill name='Tailwind CSS' x='18vw' y='18vw' />
      </div>
    </>
  );
};

export default AboutSkills;
