import { Link } from 'react-router-dom';
import Layout from './Layout';
import { CONSTANTS } from '../data/constants';

const Footer = () => {
  const { footer } = CONSTANTS;

  return (
    <footer className='w-full border-t-2 border-solid border-dark font-medium text-lg dark:text-light dark:border-light sm:text-base'>
      <Layout className='py-8 flex items-center justify-between lg:flex-col lg:py-6'>
        <span>
          {new Date().getFullYear()} &copy; {footer.copyright}
        </span>

        <div className='flex items-center lg:py-2'>
          {footer.mainText1}{' '}
          <span className='text-primary dark:text-primaryDark text-2xl px-1'>
            &#9825;
          </span>
          {footer.mainText2}&nbsp;
          <Link to='/' className='underline underline-offset-2'>
            {footer.mainText3}
          </Link>
        </div>

        <Link
          to='mailto:k.kumar28@outlook.com'
          className='underline underline-offset-2'
        >
          {footer.emailText}
        </Link>
      </Layout>
    </footer>
  );
};

export default Footer;
