import { motion } from 'framer-motion';

const NavbarIcon = ({ href, className = '', icon }) => (
  <motion.a
    href={href}
    target='_blank'
    whileHover={{ y: -2 }}
    whileTap={{ scale: 0.9 }}
    className={`${className} w-6`}
  >
    {icon}
  </motion.a>
);

export default NavbarIcon;
