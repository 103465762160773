import { useEffect, useState } from 'react';

const useTheme = () => {
  const preferDarkQuery = '(prefer-color-scheme: dark)';
  const storedMode = window.localStorage.getItem('theme');
  const [colourMode, setColourMode] = useState(storedMode || '');

  useEffect(() => {
    const mediaQuery = window.matchMedia(preferDarkQuery);

    const handleChange = () => {
      const userPreference = window.localStorage.getItem('theme');
      const checkColourMode = userPreference === 'dark' ? 'dark' : 'light';

      setColourMode(checkColourMode);

      if (checkColourMode === 'dark') {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    };

    const initializeTheme = () => {
      const userPreference = window.localStorage.getItem('theme');
      if (userPreference) {
        setColourMode(userPreference);
      } else {
        const checkColourMode = mediaQuery.matches ? 'dark' : 'light';
        setColourMode(checkColourMode);
        window.localStorage.setItem('theme', checkColourMode);
      }
    };

    handleChange();
    initializeTheme();

    mediaQuery.addEventListener('change', handleChange);

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [preferDarkQuery]);

  useEffect(() => {
    if (colourMode === 'dark') {
      window.localStorage.setItem('theme', 'dark');
      document.documentElement.classList.add('dark');
    } else {
      window.localStorage.setItem('theme', 'light');
      document.documentElement.classList.remove('dark');
    }
  }, [colourMode]);

  return [colourMode, setColourMode];
};

export default useTheme;
