import { Link } from 'react-router-dom';

import { Layout, AnimatedText } from '../../components';
import HireMe from './HireMe';

import { CONSTANTS } from '../../data/constants';
import { LinkArrow } from '../../data/icons';
import profilePic from '../../assets/images/kyle-portrait-white-square-2.jpg';
import lightBulb from '../../assets/images/miscellaneous_icons_1.svg';
import TransitionEffect from '../../components/TransitionEffect';

const Home = () => {
  const { homepage } = CONSTANTS;

  return (
    <>
      <TransitionEffect />
      <main className='flex items-center text-dark w-full min-h-screen dark:text-light'>
        <Layout className='pt-0 md:pt-16 sm:pt-8'>
          <div className='flex items-center justify-between w-full gap-8 lg:flex-col'>
            <div className='w-1/2 md:w-full'>
              <img
                src={profilePic}
                alt='Portrait'
                className='w-full h-auto dark:shadow-light lg:hidden dark:shadow-lg md:inline-block md:w-full'
              />
            </div>

            <div className='w-1/2 flex flex-col items-center self-center lg:w-full lg:text-center'>
              <AnimatedText
                text={homepage.title}
                className='!text-6xl !text-left xl:!text-5xl lg:!text-center lg:!text-6xl md:!text-5xl sm:!text-3xl'
              />
              <p className='my-4 text-base font-medium md:text-sm sm:text-xs'>
                {homepage.subtitle}
              </p>

              <div className='flex items-center self-start mt-2 lg:self-center'>
                <Link
                  to='/cv.pdf'
                  target='_blank'
                  className='flex items-center bg-dark text-light p-2.5 px-6 rounded-lg text-lg font-semibold hover:bg-light hover:text-dark border-2 border-solid border-transparent hover:border-dark transition-colors ease duration-500 dark:bg-light dark:text-dark hover:dark:bg-dark hover:dark:text-light hover:dark:border-light md:p-2 md:px-4 md:text-base'
                  download={true}
                >
                  {homepage.resumeButton} <LinkArrow className='w-6 ml-1' />
                </Link>
                <Link
                  to='mailto:k.kumar28@outlook.com'
                  target='_blank'
                  className='ml-4 text-lg font-medium capitalize text-dark underline dark:text-light md:text-base'
                >
                  {homepage.contactButton}
                </Link>
              </div>
            </div>
          </div>
        </Layout>

        <HireMe />

        <div className='absolute right-8 bottom-8 inline-block w-24 md:hidden'>
          <img src={lightBulb} alt='KyleKumar' className='w-full h-auto' />
        </div>
      </main>
    </>
  );
};

export default Home;
