import { motion, useScroll } from 'framer-motion';
import { useRef } from 'react';
import TimelineIcon from './TimelineIcon';

const TimelineData = ({ type, data }) => {
  const timelineDataRef = useRef(null);

  return (
    <li
      ref={timelineDataRef}
      className='my-8 first:mt-0 last:mb-0 w-[60%] mx-auto flex flex-col items-center justify-between md:w-[80%]'
    >
      <TimelineIcon containerReference={timelineDataRef} />
      <motion.div
        initial={{ y: 50 }}
        whileInView={{ y: 0 }}
        transition={{ duration: 0.5, type: 'spring' }}
      >
        <h3 className={'capitalize font-bold text-2xl sm:text-xl xs:text-lg'}>
          {type === 'Experience' && (
            <>
              {data.position}&nbsp;
              <a
                href={data.companyLink}
                target='_blank'
                className='text-primary dark:text-primaryDark capitalize'
              >
                @{data.company}
              </a>
            </>
          )}

          {type === 'Education' && data.type}
        </h3>

        <span className='capitalize font-medium text-dark/75 dark:text-light/75 xs:text-sm'>
          {data.time} | {type === 'Experience' && data.address}{' '}
          {type === 'Education' && data.place}
        </span>

        <p
          className='font-medium w-full md:text-sm'
          dangerouslySetInnerHTML={{
            __html:
              type === 'Experience'
                ? data.work
                : type === 'Education'
                ? data.info
                : '',
          }}
        />
        {/* {type === 'Experience' && data.work}
          {type === 'Education' && data.info} */}
        {/* </p> */}
      </motion.div>
    </li>
  );
};

const Timeline = ({ timelineType, data }) => {
  const timelineRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: timelineRef,
    offset: ['start end', 'center center'],
  });

  return (
    <div className='mt-64'>
      <h2 className='font-bold text-8xl mb-32 w-full text-center md:text-6xl xs:text-4xl md:mb-16'>
        {timelineType === 'Experience' && 'Experience'}
        {timelineType === 'Education' && 'Education'}
      </h2>

      <div
        ref={timelineRef}
        className='w-[75%] mx-auto relative lg:w-[90%] md:w-full'
      >
        <motion.div
          style={{ scaleY: scrollYProgress }}
          className='absolute left-9 top-0 w-[4px] h-full bg-dark origin-top dark:bg-light md:w-[2px] md:left-[30px] xs:left-[20px]'
        />

        <ul className='w-full flex flex-col items-center justify-between ml-4 xs:ml-2'>
          {data.map((timelineData) => (
            <TimelineData type={timelineType} data={timelineData} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Timeline;
