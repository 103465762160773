import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { LOGO_BACKGROUND } from '../../data/styles';
import { CONSTANTS } from '../../data/constants';

const MotionLink = motion(Link);

const Logo = () => {
  const { navbar } = CONSTANTS;

  return (
    <div className='flex items-center justify-center mt-2'>
      <MotionLink
        to='/'
        className='w-16 h-16 bg-dark text-light flex items-center justify-center rounded-full text-2xl font-bold border border-solid border-transparent dark:border-light'
        whileHover={{
          backgroundColor: LOGO_BACKGROUND,
          transition: { duration: 1, repeat: Infinity },
        }}
      >
        {navbar.iconTitle}
      </MotionLink>
    </div>
  );
};

export default Logo;
